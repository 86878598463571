<style>
body {
  background: #fff;
}
#login p {
  color: #333;
}
#login .ivu-input,
#login .ivu-card {
  color: #333;
  background: #fff;
}
#login .ivu-card {
  border-color: #eeeded;
}
</style>
<template>
  <div
    id="login"
    @keydown.enter="handleSubmit"
  >
    <div style="width:350px; margin:100px auto;color:#333">
      <Card class="text-center">
        <p slot="title">
          <Icon type="md-log-in" />
          OOHFORCE 工作台
        </p>
        <Alert
          type="error"
          show-icon
          v-show="showMessage"
        >{{message}}</Alert>
        <Form
          ref="loginForm"
          :model="form"
          :rules="rules"
        >
          <FormItem prop="username">
            <i-input
              type="text"
              :autofocus="true"
              v-model.trim="form.username"
              placeholder="请输入用户名"
            >
              <Icon
                color="#333"
                type="ios-person"
                slot="prepend"
              />
            </i-input>
          </FormItem>
          <FormItem prop="password">
            <i-input
              type="password"
              v-model="form.password"
              placeholder="请输入密码"
            >
              <Icon
                color="#333"
                type="ios-lock"
                slot="prepend"
              />
            </i-input>
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              @click="handleSubmit"
              long
            >登录</Button>
          </FormItem>
        </Form>
      </Card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  data () {
    return ({
      form: {
        username: 'tgadmin',
        password: '123123'
      },
      rules: {
        username: [
          { required: true, message: '账号不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { type: 'string', min: 6, message: '密码不能少于6位字符', trigger: 'blur' }
        ]
      },
      showMessage: false,
      message: ''
    })
  },
  methods: {
    handleSubmit () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.$store.dispatch('LoginByUsername', this.form).then(() => {
            this.showMessage = false
            this.$router.push({ path: '/' })
          }).catch((error) => {
            this.showMessage = true
            this.message = error
          })
        }
      })
    }
  }
}
</script>
